
/**
 * @Author: fuyonghong
 * @Date: 2024-04-16 14:30:58
 * @LastEditors: fuyonghong
 * @LastEditTime: 2024-08-12 11:50:53
 * @FilePath: \gl-system-fronted\src\lang\tsl\zh.js
 * @Description: 物模型模块中文翻译
 */
export default {
    production: {
        searchPlaceholder: '产品型号/产品版本',
        featureManagement: 'Features管理',
        addProduct: '新增产品',
        copyProduct: '复制产品',
        editProduct: '编辑产品',
        linkFeature: '关联Features',
        batchCopy: '批量复制',
        productionModel: '产品型号',
        productionVersion: '产品版本',
        productionType: '产品类型',
        deviceType: '设备类型',
        productionDescription: '产品描述',
        updateTime: '更新时间',
        deleteTips: '是否删除选中产品？',
        deleteSuccess: '删除成功',
        copyPlaceholder: '请输入新的产品版本',
        copyTips: '产品版本只能由数字、字母、下划线和.以及-组成',
        copySuccess: '复制成功',
        productionDetail: '产品详情',
        deviceCount: '设备数量',
        directDevice: '直连设备',
        gatewayDevice: '网关设备',
        subDevice: '网关子设备',
        tsl: '物模型',
        changeVersion: '变更版本',
        cancelLink: '取消关联',
        attributeKey: '属性标识',
        commandKey: '命令标识',
        eventKey: '事件标识',
        writeMode: '读写方式',
        required: '是否必填',
        type: '类型',
        executeMode: '执行方式',
        desc: '描述',
        mode: '模式',
        cancelLinkTips: '是否取消关联？',
        cancelLinkSuccess: '取消关联成功',
        changeFeature: 'Features变更',
        noEmpty: '不能为空',
        version: '版本',
        linkFeatureTips: '请选择关联Features',
        isMaskTsl: '是否为兼容产品'
    },
    feature: {
        searchPlaceholder: 'Feature名称/Feature版本',
        add: '新增',
        featureName: 'Feature名称',
        featureVersion: 'Feature版本',
        relationship: '关联关系',
        noRelationship: '暂无关联关系',
        addFeature: '新增Feature',
        copyFeature: '复制Feature',
        editFeature: '编辑Feature',
        featureEmptyTips: '请至少添加一个Feature',
        jsonError: '请输入正确的JSON格式',
        hasLinkProductVersion: '已关联产品版本',
        hasLinkProductModel: '已关联产品型号',
        featureDetail: 'Feature详情',
        isMaskTsl: '是否为兼容模型',
        sort: '排序',
    },
    columnConfig: {
        range: '显示范围',
        add: '新增数据项',
        name: '名称',
        keyAtPosition: '值获取位置',
        inputPlaceholder: 'e.x.:$.base.model,输入多个值请按回车键',
        edit: '编辑数据项',
        ruleError: '输入的值获取位置不符合规则,请检查后重新输入'
    },
    eventList: {
        status: '状态',
        model: '型号',
        latestTime: '最新时间',
        latestEvent: '最新事件',
        eventListLog: '设备事件日志',
        eventName: '事件名称',
        dateTips: '年/月/日',
        createTime: '创建时间',
        subscribeReason: '订阅原因',
        content: '内容',
        eventDetail: '事件详情',
    },
    statistics: {
        addGroup: '新增配置组',
        editGroup: '编辑配置组',
        dataSubscribeDetail: '数据订阅详情',
        allProduct: '全部产品',
        subscribedProduct: '已订阅产品',
        unsubscribedProduct: '未订阅产品',
        subscribedStatus: '订阅状态',
        featureKey: '来源Feature',
        intervals: '上报间隔',
        subscribedBySelf: '已被本组订阅',
        unsubscribed: '未被订阅',
        subscribedByOther: '已被 {groupName} 订阅',
        eventKey: '数据名/事件名',
        subscribedTips: '已被<span style="font-weight: bold;color:#409EFF; padding: 0 4px;">{groupName}</span>订阅，若继续订阅，则将从<span style="font-weight: bold;color:#409EFF;padding: 0 4px;">{groupName}</span>中移除该事件',
    },
    deviceConfig: {
        deviceMsgLog: '设备消息日志',
        statusLog: '上下线日志',
        msgLog: '消息日志',
        online: '在线',
        offline: '离线',
        status: '状态',
        deviceId: '设备ID',
        viewMsgLog: '查看消息日志',
        time: '时间',
        message: '消息',
        topic: '主题',
        msgType: '消息类型',
        msgDetail: '消息详情',
        kickOut: '踢除',
    }
}
