/*
 * @Description:
 * @Author: warren
 * @LastEditors: Warren
 * @Date: 2023-04-12 14:57:23
 * @LastEditTime: 2024-09-19 09:12:44
 */
import Cookies from 'js-cookie'
import { requestApplicationTree } from '@/api/application'

const state = {
    version: '3.0.0',
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    device: 'desktop',
    language: Cookies.get('language') || 'en',
    applicationTree: [], // 应用树
    showDateNotice: false, // 时区不一致提醒
}

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1)
        } else {
            Cookies.set('sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    SET_LANGUAGE: (state, language) => {
        state.language = language
        Cookies.set('language', language, { expires: 3650 })
    },
    SET_APPLICATION_TREE: (state, data) => {
        state.applicationTree = data
    },
    SET_CLEAR_DATA: (state, { key, value }) => {
        state[key] = value
    },
    TOGGLE_SHOW_DATE_NOTICE: (state) => {
        state.showDateNotice = !state.showDateNotice
    },
}

const actions = {
    toggleSideBar ({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar ({ commit }, { withoutAnimation }) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice ({ commit }, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setLanguage ({ commit }, language) {
        commit('SET_LANGUAGE', language)
    },

    /* 清除缓存数据 */
    actionClearData ({ commit }, key) {
        let value = ''
        if (key === 'applicationTree') {
            value = []
        }
        commit('SET_CLEAR_DATA', { key, value })
    },

    async actionSetApplicationTree ({ commit, state: { applicationTree: at } }) {
        if (at.length) {
            return at
        }
        const recursionAppTree = function (ArrayTree) {
            ArrayTree.forEach(node => {
                if (node.childrenList && node.childrenList.length) {
                    recursionAppTree(node.childrenList)
                } else {
                    delete node.childrenList
                }
            })
        }
        try {
            const { info } = await requestApplicationTree()
            recursionAppTree(info)
            commit('SET_APPLICATION_TREE', info)
            return info
        } catch (error) {
            console.log('application tree error:', error)
            return []
        }
    },
    toggleShowDateNotice ({ commit, state }) {
        const show = state.showDateNotice
        if (show) {
            commit('TOGGLE_SHOW_DATE_NOTICE')
            return
        }

        const timeout = localStorage.getItem('timeZoneNotice')
        if (timeout) {
            /* 两分钟之内 只显示一次 */
            if ((Date.now() - timeout) < 120000) {
                return false
            } else {
                localStorage.setItem('timeZoneNotice', Date.now())
            }
        } else {
            localStorage.setItem('timeZoneNotice', Date.now())
        }
        commit('TOGGLE_SHOW_DATE_NOTICE')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
