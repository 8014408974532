/**
 * @Author: fuyonghong
 * @Date: 2024-04-16 14:30:51
 * @LastEditors: fuyonghong
 * @LastEditTime: 2024-08-12 11:44:00
 * @FilePath: \gl-system-fronted\src\lang\tsl\en.js
 * @Description:物模型模块的英文翻译
 */

export default {
    production: {
        searchPlaceholder: 'Product model/Product version',
        featureManagement: 'Features Management',
        addProduct: 'Add Product',
        copyProduct: 'Copy Product',
        editProduct: 'Edit Product',
        linkFeature: 'Link Features',
        batchCopy: 'Batch Copy',
        productionModel: 'Product Model',
        productionVersion: 'Product Version',
        productionType: 'Product Type',
        deviceType: 'Device Type',
        productionDescription: 'Product Description',
        updateTime: 'Update Time',
        deleteTips: 'Whether to delete the selected product?',
        deleteSuccess: 'Delete success',
        copyPlaceholder: 'Please enter a new product version',
        copyTips: 'Product version can only be composed of numbers, letters, underscores, and . or -',
        copySuccess: 'Copy success',
        productionDetail: 'Product Detail',
        deviceCount: 'Device Count',
        directDevice: 'Direct Device',
        gatewayDevice: 'Gateway Device',
        subDevice: 'Gateway Sub-device',
        tsl: 'TSL',
        changeVersion: 'Change Version',
        cancelLink: 'Cancel Link',
        attributeKey: 'Attribute Key',
        commandKey: 'Command Key',
        eventKey: 'Event Key',
        writeMode: 'Property',
        required: 'Whether required',
        type: 'Type',
        executeMode: 'Execution mode',
        desc: 'Description',
        mode: 'Mode',
        cancelLinkTips: 'Whether to cancel the association?',
        cancelLinkSuccess: 'Cancel the association successfully',
        changeFeature: 'Features Change',
        noEmpty: 'Cannot be empty',
        version: 'Version',
        linkFeatureTips: 'Please select the associated Features',
        isMaskTsl: 'Is it a compatible product'
    },
    feature: {
        searchPlaceholder: 'Feature Name/Feature Version',
        add: 'Add',
        featureName: 'Feature Name',
        featureVersion: 'Feature Version',
        relationship: 'Relationship',
        noRelationship: 'No relationship yet',
        addFeature: 'Add Feature',
        copyFeature: 'Copy Feature',
        editFeature: 'Edit Feature',
        featureEmptyTips: 'Please add at least one feature',
        jsonError: 'Please enter the correct JSON format',
        hasLinkProductVersion: 'Linked product version',
        hasLinkProductModel: 'Linked product model',
        featureDetail: 'Feature Detail',
        isMaskTsl: 'Is it a compatible feature',
        sort: 'Sort',
    },
    columnConfig: {
        range: 'Display Range',
        add: 'Add',
        name: 'Name',
        keyAtPosition: 'Value acquisition position',
        inputPlaceholder: 'e.x.:$.base.model, press Enter to enter multiple values',
        edit: 'Edit',
        ruleError: 'The entered value acquisition position does not meet the rules, please check and re-enter'
    },
    eventList: {
        status: 'Status',
        model: 'Model',
        latestTime: 'Latest Time',
        latestEvent: 'Latest Event',
        eventListLog: 'Device Event Log',
        eventName: 'Event Name',
        dateTips: 'Year/Month/Day',
        createTime: 'Create Time',
        subscribeReason: 'Subscribe Reason',
        content: 'Content',
        eventDetail: 'Event Detail',
    },
    statistics: {
        addGroup: 'Add Configuration Group',
        editGroup: 'Edit Configuration Group',
        dataSubscribeDetail: 'Data Subscription Detail',
        allProduct: 'All Products',
        subscribedProduct: 'Subscribed Products',
        unsubscribedProduct: 'Unsubscribed Products',
        subscribedStatus: 'Subscribed Status',
        featureKey: 'Feature Key',
        intervals: 'Intervals',
        subscribedBySelf: 'Subscribed by Self',
        unsubscribed: 'Unsubscribed',
        subscribedByOther: 'Subscribed by {groupName}',
        eventKey: 'Event Key',
        subscribedTips: 'Subscribed by <span style="font-weight: bold;color:#409EFF; padding: 0 4px;">{groupName}</span>, if you continue to subscribe, the event will be removed from <span style="font-weight: bold;color:#409EFF;padding: 0 4px;">{groupName}</span>',
    },
    deviceConfig: {
        deviceMsgLog: 'Device Message Log',
        statusLog: 'Status Log',
        msgLog: 'Message Log',
        online: 'Online',
        offline: 'Offline',
        status: 'Status',
        deviceId: 'Device ID',
        viewMsgLog: 'View Message Log',
        time: 'Time',
        message: 'Message',
        topic: 'Topic',
        msgType: 'Message Type',
        msgDetail: 'Message Detail',
        kickOut: 'Kick Out',
    }

}
