<!--
 * @Description: 菜单
 * @Author: warren
 * @LastEditors: Warren
 * @Date: 2023-04-12 09:55:58
 * @LastEditTime: 2024-08-02 15:20:24
-->
<template>
    <div :class="{'has-logo':showLogo}">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <div class="scrollbar-wrapper" :style="{'--height': height}">
            <el-menu
                :default-active="$route.meta.activeMenu? $route.meta.activeMenu : $route.name"
                mode="vertical"
                :unique-opened="false"
                :collapse="isCollapse"
                :collapse-transition="false"
                text-color="#bfcbd9"
                active-text-color="#409EFF"
            >
                <sidebar-item v-for="(route) in permission_routers" :key="route.name" :item="route" :base-path="route.path" />
            </el-menu>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
    components: { SidebarItem, Logo },

    computed: {
        ...mapGetters([
            'sidebar',
            'permission_routers'
        ]),
        routes () {
            return this.$router.options.routes
        },
        activeMenu () {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        showLogo () {
            return this.$store.state.settings.sidebarLogo
        },
        variables () {
            return variables
        },
        isCollapse () {
            return !this.sidebar.opened
        },
        height () {
            return this.isCollapse ? 'calc(100% - 36px)' : 'calc(100% - 110px)'
        }
    }
}
</script>
<style scoped lang="scss">
.scrollbar-wrapper {
    height: var(--height);
    overflow-y: auto;
}
</style>
