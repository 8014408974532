/*
 * @Description: 公共部分
 * @Author: warren
 * @LastEditors: lijiaxin
 * @Date: 2023-04-21 09:32:54
 * @LastEditTime: 2024-01-08 11:56:20
 */
export default {
    common: {
        password: '密码',
        success: '成功',
        error: '错误',
        online: '在线',
        offline: '离线',
        total: '总数',
        device: '设备',
        user: '用户',
        onlineDevice: '在线设备',
        offlineDevice: '离线设备',
        inactiveDevice: '未激活设备',
        username: '用户名',
        email: '邮箱',
        phone: '电话',
        role: '角色',
        search: '查询',
        status: '状态',
        company: '公司',
        lastLoginTime: '最后登录时间',
        action: '操作',
        enable: '启用',
        disenable: '未启用',
        description: '描述',
        effective: '有效',
        invalid: '无效',
        cancel: '取消',
        apply: '应用',
        confirm: '确认',
        prompt: '提示',
        name: '名称',
        createTime: '创建时间',
        updateTime: '更新时间',
        edit: '编辑',
        delete: '删除',
        warning: '注意',
        select: '选择',
        refresh: '刷新',
        details: '详情',
        deviceID: '设备ID',
        MAC: 'MAC',
        model: '型号',
        version: '版本',
        groupName: '组名称',
        onlineTime: '上线时间',
        offlineTime: '离线时间',
        bindTime: '绑定绑定时间',
        server: '服务器',
        IPAddress: 'IP地址',
        port: '端口',
        lanIP: '局域网IP',
        complete: '完成',
        fail: '失败',
        type: '类型',
        view: '查看',
        modify: '修改',
        reset: '重置',
        ok: '确认',
        SSID: 'SSID',
        selectLength: '请选择一条数据',
        add: '添加',
        next: '下一步',
        back: '上一步',
        actionSuccess: '操作成功',
        userNotExist: '用户不存在',
        inviteConfirm: '邀请',
        inviteEmailTips: '该邮箱未注册，是否邀请该用户注册？',
        invitePhoneTips: '该手机号未注册，是否邀请该用户注册？',
        findUserError: '查询用户信息失败',
        deleteTips: '确定要删除此项吗？',
        deleteSuccess: '删除成功',
        dispatch: '分配权限',
        copy: '复制',
        filter: '筛选',
        noData: '暂无数据',
        yes: '是',
        no: '否',
        sync: '同步',
        async: '异步',
        preview: '预览',
        clear: '清除',
        backPage: '返回',
        config: '配置',
    },
}
